import type { LibraryLocation } from 'generated/graphql';
import type {
    ContactLoaderData,
    LocationDataType,
    LocationStateType,
    ProfessionalInitialType,
} from '../type/contact-page-types';
import type { getLinkedProfiles } from '../api/get-linked-profiles.server';

export const getInitialContactID = (
    urlLocationState: LocationStateType,
    searchParams: URLSearchParams
): number | null => {
    if (urlLocationState?.ID) {
        return urlLocationState.ID;
    }
    const locationID = searchParams.get('ID');
    if (locationID) {
        return parseInt(locationID);
    }
    return null;
};

export const convertLocationData = (
    location: Partial<LocationDataType | LibraryLocation>
): LocationDataType => {
    return {
        ID: location.ID || 0,
        Title: location.Title || '',
        Type: location.Type || '',
        Phone: location.Phone || '',
        Address1: location.Address1 || '',
        Address2: location.Address2 || '',
        Suburb: location.Suburb || '',
        City: location.City || '',
        Region: location.Region || '',
        Country: location.Country || '',
        PostCode: location.PostCode || '',
        MapCoordinates: location.MapCoordinates || {
            Latitude: 0,
            Longitude: 0,
        },
        AllowsPickup: location.AllowsPickup || false,
    };
};

export const sortLocationsByLatitude = (locations: LocationDataType[]) => {
    // Sort locations from North to South by default
    return locations.sort((a, b) => {
        return b.MapCoordinates.Latitude - a.MapCoordinates.Latitude;
    });
};

export const getLinkedProfileLocations = (
    professional: Pick<ProfessionalInitialType, 'LibraryLocations'>,
    linkedProfiles: Pick<
        NonNullable<Awaited<ReturnType<typeof getLinkedProfiles>>>,
        'linkedProfilesCount' | 'linkedProfilesList'
    > | null
): LocationDataType[] => {
    const professionalLocations = professional.LibraryLocations.filter(
        (l) => !l.LocationHidden
    ).map(convertLocationData);

    if (!linkedProfiles || linkedProfiles.linkedProfilesCount === 0) {
        return sortLocationsByLatitude(professionalLocations);
    }

    const linkedProfilesLocations = linkedProfiles.linkedProfilesList.reduce(
        (locations, profile) => {
            const filteredLocations = profile.LibraryLocations.filter(
                (ll) => !ll.LocationHidden
            );
            for (const location of filteredLocations) {
                if (locations.some((l) => l.ID === location.ID)) {
                    continue;
                }

                locations.push({
                    ...convertLocationData(location),
                    professionalID: profile.ID,
                });
            }
            return locations;
        },
        professionalLocations
    );

    return sortLocationsByLatitude(linkedProfilesLocations);
};

export const getFullAddress = (
    location: LocationDataType,
    withCountry = false
): string => {
    return [
        location.Address1,
        location.Address2,
        location.Suburb,
        location.City,
        location.Region,
        withCountry ? location.Country : undefined,
        location.PostCode,
    ]
        .filter(Boolean)
        .join(', ');
};

export const getLocationSuburbText = (
    location: LocationDataType | undefined
) => {
    if (!location) {
        return '';
    }
    const locationArray = [
        location.Suburb,
        location.City || location.Region,
    ].filter(Boolean);
    if (locationArray[0] === locationArray[1]) return locationArray[0];
    else return locationArray.join(', ');
};

export const scrollToRefTop = (
    ref: React.RefObject<HTMLDivElement>,
    offset = 20
) => {
    ref.current &&
        window.scrollTo({
            top: ref.current.offsetTop - offset,
            behavior: 'smooth',
        });
};

export const getLocationProfessional = (
    location: LocationDataType,
    professional: ProfessionalInitialType,
    linkedProfiles?: ContactLoaderData['linkedProfiles']
) => {
    const locationProfessional =
        location.professionalID && linkedProfiles
            ? linkedProfiles.linkedProfilesList.find(
                  (p) => p.ID === location.professionalID
              ) || professional
            : professional;

    return { ...locationProfessional, Link: professional.Link };
};
